import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/config'
import _ from 'lodash';
const Meta = props => {
    let cityWidget 
    if(_.split(props.path,"/").includes('malmo')){
      cityWidget = "Malmö"
    }else if(_.split(props.path,"/").includes('uppsala')){
      cityWidget = "Uppsala"
    }else{
      cityWidget = "Sverige";
    }
     
    const params = props.computedMatch.params;
    const category = _.has(params,'category') ? params.category : null;
    let description
    let title
    if(category)
    {
      title = !_.isEmpty(config.titleMap[category]) && config.titleMap[category].replace('city',cityWidget)
      description = !_.isEmpty(config.titleMap[category]) && config.descriptionMap[category].replace('city',cityWidget)
    }
    else{
      title = config.titleMap['evenemang'].replace('city',cityWidget)
      description = config.descriptionMap['evenemang'].replace('city', cityWidget)
    }
  return (
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, 
            maximum-scale=1.0, user-scalable=no"/>
        <title>{title}</title>
        <meta content={title} property="og:title" />
        <meta content={description} property="og:description" />
        <meta name="description" content={description}/> 
        <meta content={'https://aftonbladet.se' + props.location.pathname} property="og:url" />
        <link rel="canonical" href={'https://aftonbladet.se' + props.location.pathname} />
      </Helmet>
  )
}

export default Meta;

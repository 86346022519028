import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import helpers from "./utils/helpers";
import WidgetComponent from "./components/WidgetComponent";
import EventPage from "./components/EventPage";
import Meta from "./components/Meta";

const RouteWithMeta = ({ component: Component, ...props }) => {
  return (
    <Switch>
      <Route
        {...props}
        render={routeProps => (
          <React.Fragment>
            <Meta {...props} />
            <Component {...routeProps} {...props} />
          </React.Fragment>
        )}
      />
    </Switch>
  );
};

const RedirectToEvenemang = () => {
  // Redirect to "/evenemang" when this component is rendered
  return <Redirect to="/evenemang/" />;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      cities: []
    };
  }

  componentDidMount() {
    var supportsTouch = helpers.isTouchDevice();
    if (supportsTouch) {
      document.getElementsByTagName("body")[0].classList.add("abTouch");
    } else {
      document.getElementsByTagName("body")[0].classList.add("abNoTouch");
    }
  }
  // basename="evenemang"
  render() {
    return (
      <>
        <BrowserRouter>
          <Switch>
            {/* <RouteWithMeta
              exact
              path="/evenemang/malmo/"
              component={WidgetComponent}
            /> */}
            {/* <RouteWithMeta
              exact
              path="/evenemang/malmo/alla/"
              component={WidgetComponent}
            /> */}
            <RouteWithMeta
              exact
              path="/evenemang/malmo/:category/:city/"
              component={WidgetComponent}
            />
            <RouteWithMeta
              exact
              path="/evenemang/malmo/:category/"
              component={WidgetComponent}
            />
            <Route
              exact
              path="/evenemang/malmo/:category/:city/:eventTitle"
              component={EventPage}
            />
            <RouteWithMeta
              exact
              path="/evenemang/uppsala/"
              component={WidgetComponent}
            />
            <RouteWithMeta
              exact
              path="/evenemang/uppsala/alla/"
              component={WidgetComponent}
            />
            <RouteWithMeta
              exact
              path="/evenemang/uppsala/:category/:city/"
              component={WidgetComponent}
            />
            <RouteWithMeta
              exact
              path="/evenemang/uppsala/:category/"
              component={WidgetComponent}
            />
            <Route
              exact
              path="/evenemang/uppsala/:category/:city/:eventTitle"
              component={EventPage}
            />
            <Route
              exact
              path="/evenemang/alla/:category/:city/:eventTitle"
              component={EventPage}
            />
            <Route exact path="/evenemang/malmo/">
              {<Redirect to="/evenemang/malmo/alla/" />}
            </Route>
            <Route exact path="/evenemang/malmo/">
              {<Redirect to="/evenemang/malmo/alla/" />}
            </Route>
            {/* <Route exact path="/evenemang/">
              {<Redirect to="/evenemang/alla/" />}
            </Route> */}
            <RouteWithMeta
              exact
              path="/evenemang/"
              component={WidgetComponent}
            />
             <RouteWithMeta
              exact path="/" component={RedirectToEvenemang} 
            />
            <RouteWithMeta
              exact
              path="/evenemang/:category/:city/"
              component={WidgetComponent}
            />
            <RouteWithMeta
              exact
              path="/evenemang/:category/"
              component={WidgetComponent}
            />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default App;

import React from "react";
import { Link } from "react-router-dom";
import helpers from "../utils/helpers";
import _ from "lodash";
import config from "../utils/config";
class Eventbox extends React.Component {
  titleCase = str => {
    var arr = str.split(",").map(function(item) {
      return item.trim();
    });
    str = arr.join(", ");
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  eventBoxContent = () => {
    return (
      <div className="abew--eventbox">
        <div className="abew--date">
          <p>{this.props.date}</p>
          <div className="abew--info">
            <h2>{this.props.name}</h2>
            <p>
              {_.replace(this.props.location, /,/g, ", ")} -{" "}
              {this.props.category}
            </p>
          </div>
          <div className="abew--arrow" />
        </div>
      </div>
    );
  };

  render() {
    let slugifiedTitle = "";
    slugifiedTitle =
      this.props.name &&
      helpers.slugifyText(this.props.name) + "_" + this.props.id;
    let redirectLink;
    let target = "_parent";
    // if (this.props.widget === "ticketmaster") {
    //   redirectLink = this.props.link;
    //   target = "_blank";
    // } else
    redirectLink =
      "/evenemang/" +
      this.props.widget +
      "/" +
      helpers.slugifyText(this.props.category) +
      "/" +
      helpers.slugifyText(this.props.city) +
      "/" +
      slugifiedTitle;
    return (
      <div
        className={
          "abew--eventboxes " +
          (_.lowerCase(this.props.category)
            ? _.lowerCase(this.props.category)
            : "ovrigt")
        }
      >
        {/* {this.props.widget === config.TICKET_MASTER ? (
          <a key={this.props.key} href={redirectLink} target={target}>
            {this.eventBoxContent()}
          </a>
        ) : (
          <Link key={this.props.key} to={redirectLink} target={target}>
            {this.eventBoxContent()}
          </Link>
        )} */}
        <Link key={this.props.key} to={redirectLink} target={target}>
          {this.eventBoxContent()}
        </Link>
      </div>
    );
  }
}

export default Eventbox;

import React from "react";
import ReactDOM from "react-dom";

class Event extends React.Component {
  render() {
    let date_text_obj = {
      "": "Alla dagar",
      "today": "Idag",
      "this_week": "I veckan",
      "this_weekend": "I helgen"
    }
    return (
      <React.Fragment>
        <div className="abew--event">
          <div className="abew--fact">
            <div className="abew--day">
              <p>{this.props.date_text ? date_text_obj[this.props.date_text] : 'Alla dagar'}</p>
            </div>
            <div className="abew--amount">
              <p>{this.props.count} evenemang</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Event;

import config from "./config";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import _ from "lodash";
import helpers from "./helpers";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 5 * 60 * 1000
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter
});

var apicall = {
  getCalenderEvents: async function(widget) {
    const URL =
      config.eventsBaseURL + `calender.json?new=true&widget=${widget}&page=1&per_page=100`;
    let resposeObj = {};
    await api
      .get(URL, {
        validateStatus: function(status) {
          return status < 400;
        }
      })
      .then(resp => {
        resposeObj.events = resp.data.results;
        resposeObj.total_events = resp.data.total;
      })
      .catch(err => {
        console.log("error");
      });
    return resposeObj;
  },
  filterCalenderEvents: async function(
    category,
    city,
    date_text,
    per_page,
    page,
    widget
  ) {
    let resposeObj = {};
    let listofEvents = [];
    if (page == 1) {
      config.localforage
        .removeItem("events")
        .then(function() {
          // Run this code once the key has been removed.
          // console.log('Key is cleared!');
        })
        .catch(function(err) {
          // This code runs if there were any errors
          console.log(err);
        });
    }
    if (page !== 1) {
      await config.localforage.getItem("events", (err, events) => {
        if (typeof events !== "undefined" && events !== null) {
          listofEvents = events;
        }
      });
    }
    category = category === "alla" ? "" : config.categoryMap[category];
    // if (date)
    //     url = `${url}&date=${dayjs(date).format('YYYY-MM-DD')}`
    let requestObj = {};
    if (category) requestObj.category = category;
    if (city && city !== "alla"){
      requestObj.city = await helpers.getCityName(`city-map-${widget}`, city);
    }
    if (date_text) requestObj.date = date_text;
    requestObj.per_page = per_page;
    requestObj.page = page;
    requestObj.widget = widget;
    let query = Object.keys(requestObj)
      .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(requestObj[k]))
      .join("&");
    let url = config.eventsBaseURL + "calender.json?new=true&" + query;
    let calenderEvents;
    await api
      .get(url, {
        validateStatus: function(status) {
          return status < 400;
        }
      })
      .then(resp => {
        let events = resp.data.results;
        resposeObj.total_events = resp.data.total;
        if (events.length > 0) {
          listofEvents.push(...events);
        }
        resposeObj.events = listofEvents;
        config.localforage.setItem("events", listofEvents, function(err) {});
      })
      .catch(err => {
        console.log("error");
      });
    return resposeObj;
  },
  getCities: async function(widget) {
    const URL = config.eventsBaseURL + `cities?widget=${widget}&new=true`;
    let cities;
    await api
      .get(URL)
      .then(resp => {
        cities = resp.data;
        const citiesMap = cities.reduce(
          (x, key) => ({ ...x, [_.lowerCase(key)]: key }),
          {}
        );
        config.localforage.setItem(`city-map-${widget}`, citiesMap, function(
          err
        ) {});
      })
      .catch(err => {
        console.log("error");
      });
    return cities;
  },
  getEventDetails: async function(eventId) {
    const URL = `${config.eventsBaseURL} +${eventId}/show_event.json`;
    let eventDetails;
    await api
      .get(URL)
      .then(resp => {
        eventDetails = resp.data;
      })
      .catch(err => {
        console.log("error");
      });
    return eventDetails;
  },
  getCategories: async function(city, date, widget) {
    let requestObj = {};
    if (date) requestObj.date = date;
    if (city && city != "alla")
      requestObj.city = await helpers.getCityName(`city-map-${widget}`, city);
    requestObj.widget = widget;
    requestObj.new = true;
    let query = Object.keys(requestObj)
      .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(requestObj[k]))
      .join("&");
    const URL = config.eventsBaseURL + "categories?" + query;
    let categories;
    await api
      .get(URL)
      .then(resp => {
        categories = resp.data;
        config.localforage.setItem("categoryArray", categories, function(
          err
        ) {});
      })
      .catch(err => {
        console.log("error");
      });
    return categories;
  }
};

export default apicall;

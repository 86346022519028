import config from "./config";
import slugify from "slugify";
import _ from "lodash";
var helpers = {
  replaceText: function(text, date) {
    if (typeof text !== "undefined" && text !== null) {
      return text.replace("date_replace", date);
    }
  },
  replaceAll: function(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
  },
  isEmpty: function(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return JSON.stringify(obj) === JSON.stringify({});
  },
  debounced: function(delay, fn) {
    let timerId;
    return function(...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  },
  immutableUnshift: function(arr, newEntry) {
    return [newEntry, ...arr];
  },
  immutablePush: function(arr, newEntry) {
    return [...arr, newEntry];
  },
  capitalizeFirstLetter: function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  pad: function(str, size) {
    var str = String(str);
    while (str.length < (size || 2)) {
      str = "0" + str;
    }
    return str;
  },
  compare: function(a, b) {
    if (a.offset < b.offset) return -1;
    if (a.offset > b.offset) return 1;
    return 0;
  },
  replace: function(str) {
    return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
  },
  boldString: function(str, find) {
    var re = new RegExp(find, "ig");
    return str.replace(re, "<b>" + find + "</b>");
  },
  isTouchDevice: function() {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
    var mq = function(query) {
      return window.matchMedia(query).matches;
    };
    if ("ontouchstart" in window || window.DocumentTouch) {
      return true;
    }
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
      ""
    );
    return mq(query);
  },
  slugifyText: function(text) {
    if (typeof text !== "undefined" && text !== null) {
      return slugify(text, {
        replacement: "-",
        remove: /[=*+~.,()'":@!#?/]/g,
        lower: true
      });
    }
  },
  generateTitle: function(city, title) {
    if(city){
      const concatCity = " - " + city + " Evenemang";
      const truncatedTitle = _.truncate(title, {
        length: 70 - city.length
      });
      return truncatedTitle + concatCity;
    }
  },
  downloadEvent: function(event, key, filename) {
    const url = window.URL.createObjectURL(new Blob([event]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", key + `.ics`);

    // 3. Append to html page
    document.body.appendChild(link);

    // 4. Force download
    link.click();

    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  },
  replaceHttp: function(url) {
    if (url) return url.replace(/^http:\/\//i, "https://");
  },
  getCityName: async function(key, city) {
    let cityName = null;
    await config.localforage.getItem(key).then(cityMap => {
      cityName = cityMap && cityMap[city];
    });
    if(cityName)
      return cityName;
    else 
      return config.cityMap[city]
  },
  getCategoryNames: async function(category) {
    let categoryName;
    await config.localforage.getItem("categoryArray").then(categoryArray => {
      categoryArray.map((categoryObject, index) => {
        categoryName = categoryObject[category];
      });
    });
    return categoryName;
  }
};

export default helpers;

import localforage from "localforage";
const config = {
  displayAds: false,
  eventsBaseURL: "https://event-widget.cortexcraft.com/events/",
  MALMO_COORDINATES: {
    lat: 55.787578,
    lng: 13.066501
  },
  UPPSALA_COORDINATES: {
    lat: 59.8586,
    lng: 17.6389
  },
  debounceTimer: 500,
  localforage: localforage.createInstance({
    name: "aftonbladet-events"
  }),
  mapAccessToken:
    "pk.eyJ1IjoicHJha2FzaGR1bmRlcmRvZyIsImEiOiJjbHI5MWw4NGQwMWE3MmlucDQ3NWxpZHpiIn0.AOVZZ8uDFVzB966RygSKUQ",

  titleMap: {
    evenemang: "Evenemang i city, det händer idag | Vahander",
    konsert: "Konsert i city, se vilka konserter som är idag | Vahander",
    konst: "Utställningar i city, konst att se idag | Vahander",
    barn: "Barn i city, barnaktiviteter som sker idag | Vahander",
    sport: "Sport i city, sportevenemang att gå på idag | Vahander",
    forelasning: "Föreläsningar i city, det händer idag | Vahander",
    teater: "Teater i city, det händer idag | Vahander",
    show: "Show & Musikal i city, här hittar du biljetterna | Vahander",
    dans: "Dans i city, se aktuella dansföreställningar | Vahander",
    festivaler: "Festivaler i city, se vad som händer idag | Vahander",
    klubb: "Klubb city, se vad som händer idag | Vahander",
    ovrigt: "Övriga evenemang i city | Vahander",
    noje: "Nöjesevenemang i city idag | Vahander",
  },
  descriptionMap: {
    evenemang:
      "Hitta något att göra idag. Aftonbladet samlar alla evenemang i city, hitta något som passar dig, eller lägg upp ett evenemang, så att folk hittar till ditt evenemang.",
    konsert:
      "Hitta något att göra idag. Aftonbladet samlar alla konserter i city, hitta något som passar dig, eller lägg upp din konsert, så att folk hittar till ditt evenemang.",
    konst:
      "Hitta något att göra idag. Aftonbladet samlar alla konstutställningar i city, hitta något som passar dig, eller lägg upp din utställning, så att folk hittar till ditt evenemang.",
    barn:
      "Hitta något att göra idag. Aftonbladet samlar alla evenemang för barn i city, hitta något som passar ditt barn, eller lägg upp ett evenemang, så att folk hittar till ditt evenemang.",
    sport:
      "Hitta något att göra idag. Aftonbladet samlar alla sportevenemang i city, hitta något som passar dig, eller lägg upp ett sportevenemang, så att folk hittar till ditt evenemang.",
    forelasing:
      "Hitta något att göra idag. Aftonbladet samlar alla föreläsningar i city, hitta något som passar dig, eller lägg upp en föreläsning, så att folk hittar till ditt evenemang.",
    teater:
      "Hitta något att göra idag. Aftonbladet samlar alla teaterförestälningar i city, hitta något som passar dig, eller lägg upp din föreställning, så att folk hittar till ditt evenemang.",
    show:
      "Hitta något att göra idag. Aftonbladet samlar alla shower och musikaler  i city, hitta något som passar dig, eller lägg till ditt evenemang, så att folk hittar till ditt evenemang.",
    dans:
      "Hitta något att göra idag. Aftonbladet samlar alla evenemang i city, hitta något som passar dig, eller lägg upp ett evenemang, så att folk hittar till ditt evenemang.",
    festivaler:
      "Hitta något att göra idag. Aftonbladet samlar alla dansföreställningar i city, hitta något som passar dig, eller lägg till en dansföreställning, så att folk hittar till ditt evenemang.",
    klubb:
      "Hitta något att göra idag. Aftonbladet samlar alla klubbar kring city, hitta något som passar dig, eller lägg till en klubb, så att folk hittar till ditt evenemang.",
    ovrigt:
      "Hitta något att göra idag. Aftonbladet samlar alla evenemang i city här hittar du okategoriserade evenemang, hitta något som passar dig, eller lägg upp ett evenemang, så att folk hittar till ditt evenemang."
  },
  categoryMap: {
    alla: "Alla",
    noje: "Nöje",
    musik: "Musik",
    kultur: "Kultur",
    konst: "Konst",
    barn: "Barn",
    ovrigt: "Övrigt",
    festival: "Festival",
    forelasning: "Föreläsning",
    klubb: "Klubb",
    show: "Show",
    teater: "Teater",
    sport: "Sport"
  },
  cityMap: {
    malmo: "Malmö",
    jarrestad: "Järrestad",
    lund: "Lund",
    skanor: "Skanör",
    torup: "Torup",
    limhamn: "Limhamn",
    hollviken: "Höllviken",
    ystad: "Ystad",
    vellinge: "Vellinge",
    svedala: "Svedala",
    uppsala: "Uppsala",
    ticketmaster: "Sverige"
  },
  TICKET_MASTER: "alla",
};

export default config;

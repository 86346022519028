import React, { useState, useEffect } from "react";
import "../styles/event-page.scss";
import apicall from "../utils/apicall";
import dayjs from "dayjs";
import helpers from "../utils/helpers";
import WidgetComponent from "./WidgetComponent";
import renderHTML from "react-render-html";
import Helmet from "react-helmet";
import _ from "lodash";
import config from "../utils/config";
import * as ics from "ics";
import vad_hander from "../images/vad-hander-logo.svg";
export default class EventPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      event: null,
      showMore: false,
      showAdd: true
    };
  }
  UNSAFE_componentWillMount() {
    var eventId = null;
    if (this.props.match) {
      eventId = _.split(this.props.match.params.eventTitle, "_")[1];
    }
    this.fetchEventDetails(eventId);
    const path = this.props.match.path;
    console.log("props==>", path);
    let widget;
    if (path.includes("/evenemang/malmo/")) widget = "malmo";
    else if (path.includes("/evenemang/uppsala/")) widget = "uppsala";
    else widget = config.TICKET_MASTER;
    let cityName;
    cityName = config.cityMap[widget];
    this.setState({
      widget: widget,
      cityName: cityName
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let eventId = null;
    if (newProps !== this.props) {
      if (newProps.match) {
        eventId = _.split(newProps.match.params.eventTitle, "_")[1];
      }
      this.fetchEventDetails(eventId);
    }
  }

  fetchEventDetails = eventId => {
    apicall.getEventDetails(eventId).then(resp => {
      let event = resp;
      this.setState({ event: event });
      const eventObject = {
        start: dayjs(event.actual_start_datetime)
          .format("YYYY-M-D-H-m")
          .split("-"),
        end: dayjs(event.actual_end_datetime)
          .format("YYYY-M-D-H-m")
          .split("-"),
        title: event.name,
        description: event.header_message,
        location: _.replace(event.campaign_address, /,/g, ", "),
        url: event.campaignUrl,
        geo: { lat: event.latitude, lon: event.longitude },
        categories: [event.category],
        status: "CONFIRMED"
        // organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
        // attendees: [
        //   { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
        //   { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
        // ]
      };
      ics.createEvent(eventObject, (error, value) => {
        if (error) {
          console.log(error);
          return;
        }
        this.setState({
          icsEvent: value
        });
      });
    });
  };

  render() {
    const event = this.state.event;
    let metaDescription;
    let date = null;
    let body_message =
      event && event.body_message.toString().replace(/\\"/g, '"');
    let processed_body_message =
      body_message && body_message.replace(/\r\n/g, "<br />");
    let isSameDate =
      event &&
      dayjs(event.actual_start_datetime).isSame(
        dayjs(event.actual_end_datetime),
        "day"
      );
    if (event && (isSameDate || event.actual_end_datetime === null))
      date =
        event &&
        `${dayjs(event.actual_start_datetime)
          .locale("sv")
          .format("D MMM")}`;
    else
      date =
        event &&
        `${dayjs(event.actual_start_datetime).format("D")}-${dayjs(
          event.actual_end_datetime
        )
          .locale("sv")
          .format("D MMM")}`;

    return event ? (
      <>
        <Helmet>
          <meta
            name="description"
            content={_.truncate(event.header_message, {
              length: 153
            })}
          />
          <title>
            {helpers.generateTitle(
              config.cityMap[this.state.widget],
              event.name
            )}
          </title>
          <link
            rel="canonical"
            href={"https://aftonbladet.se" + this.props.location.pathname}
          />
          <meta
            content={helpers.generateTitle(
              config.cityMap[this.state.widget],
              event.name
            )}
            property="og:title"
          />
          <meta
            content={_.truncate(event.header_message, {
              length: 153
            })}
            property="og:description"
          />
          <meta
            content={"https://aftonbladet.se" + this.props.location.pathname}
            property="og:url"
          />
          <script type="application/ld+json">
            {`{
                    '@context': 'http://schema.org',
                    "@type": "Event",
                    "name": ${event.name},
                    "startDate": ${event.actual_start_datetime}
                    "endDate" : ${event.actual_end_datetime}
                    "description": ${_.truncate(event.header_message, {
                      length: 100
                    })}
                    "location": {
                      "@type": "Place",
                      "address": {
                        "@type": "PostalAddress",
                        "addressLocality": ${event.city},
                        "addressRegion": "",
                        "postalCode": "",
                        "streetAddress": ${!_.isEmpty(event.api_response) &&
                          event.api_response.showdateStreet},
                        "country":"Sweden"
                      },
                      "name": ${event.location}
                    },
                    "offers": {
                      "@type": "Offer",
                      "price": ${event.campaign_price},
                      "priceCurrency": "SEK",
                      "url": ${window.location.href}
                      "availability":"",
                      "validFrom":""
                    },
                    performer{
                      @type: "",
                      name:""
                    }
                    "image":[${event.campaign_image}]
                    "
                  }`}
          </script>
        </Helmet>
        <div className="abep--page">
          <div className="abep--pageheader">
            <a
              onClick={() => this.props.history.goBack()}
              className="abep--back-link"
            >
              Tillbaka
            </a>
            <a
              href="https://vahander.se/evenemang/malmo/"
              className="abep--pageheader-logo"
            >
              <img src={vad_hander} alt="Logo Aftonbladet" />
              <div className="logo-divider">/</div>
              <img
                src="/evenemang/uppsala/static/logo-malmo-v2.svg"
                className="hide"
                alt="Logo Malmö"
              />
            </a>
            <div className="last-child"></div>
          </div>
          <div className="abep--event">
            <div className="abep--eventinfo">
              <div className="abep--eventheader">
                <img
                  className=""
                  src={helpers.replaceHttp(event.campaign_image)}
                  alt=""
                />
                <h1>{event.name}</h1>
                {!_.isEmpty(event.organizer_image_url) && (
                  <div className="abep--organizer-logo">
                    <img src={event.organizer_image_url} alt="" />
                    Arrangör
                  </div>
                )}
              </div>
              <div className="abep--eventdetails">
                <div className="abep--eventlocation">
                  <p>
                    <span className="icon icon__map-pin"></span>
                    {event.location}
                  </p>
                </div>

                <div className="abep--eventtime">
                  <p>
                    <strong>{date}</strong>
                    Datum
                  </p>
                  <p>
                    <strong>
                      {dayjs(event.actual_start_datetime).format("HH:mm")}
                    </strong>
                    Tid
                  </p>
                  <p>
                    {event.campaign_price === "0" ? (
                      <strong>
                        {`${event.campaign_price}(${event.ordinary_price})`} kr
                      </strong>
                    ) : (
                      <strong> Gratis </strong>
                    )}
                    Pris
                  </p>
                </div>

                <div className="abep--eventcategory hide">
                  <div className="abep--categoryicon">
                    <img
                      src={`/evenemang/uppsala/static/active_state/${helpers.slugifyText(
                        event.category
                      )}.svg`}
                      alt="Ikon kategori"
                    />
                  </div>
                </div>

                <div className="abep--eventpricelink hide">
                  <div className="abep--eventpricelink-site">
                    Länk till eventet:
                    <span>
                      <a href={event.campaignUrl}>{event.campaignurl_text}</a>
                    </span>
                  </div>
                </div>
                <div className="abep--event-buttons">
                  <a
                    href={event.campaignUrl}
                    className="button button-red button-ticket"
                  >
                    <div className="icon icon__tickets"></div>
                    {event.name !== event.campaignurl_text
                      ? event.campaignurl_text
                      : "Till biljetter"}
                  </a>
                  <button className="button button-calendar">
                    <div className="icon icon__calendar"></div>
                    <p
                      onClick={e => {
                        helpers.downloadEvent(
                          this.state.icsEvent,
                          helpers.slugifyText(event.name)
                        );
                        e.preventDefault();
                      }}
                    >
                      Lägg till i kalender
                    </p>
                  </button>
                </div>
              </div>
              {window.innerWidth < 500 &&
                this.state.widget ===
                  "uppsala"(
                    <>
                      <div
                        className="ad-iframe"
                        style={{ textAlign: "center" }}
                      >
                        <iframe
                          src="https://secure.adnxs.com/tt?id=18026689&size=320x320&kw_se-aftonbladet-local=uppsala"
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                          topMargin="0"
                          leftMargin="0"
                          allowTransparency="true"
                          width="320"
                          height="320"
                        ></iframe>
                      </div>
                      {/* <AppNexusAd/> */}
                    </>
                  )}

              <div className="abep--eventtext dont-break-out">
                <div className="abep--eventtext-header">
                  <p>
                    {renderHTML(
                      event.header_message.toString().replace(/\\"/g, '"')
                    )}
                  </p>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: processed_body_message
                  }}
                ></div>
                <div
                  className={
                    !this.state.showMore
                      ? "abep--eventtext-more read-more hide"
                      : "abep--eventtext-more read-less"
                  }
                >
                  <p>More content</p>
                </div>
                <div
                  className="abep--organizer-url"
                  style={{ marginTop: "5px" }}
                >
                  Arrangörer:{" "}
                  <a href={event.organizer_url}>{event.organizer_url}</a>
                </div>
              </div>
              <div
                className={
                  !this.state.showMore
                    ? "abep--readmore hide"
                    : "abep--readmore hide"
                }
                onClick={() =>
                  this.setState({ showMore: !this.state.showMore })
                }
              >
                {!this.state.showMore ? <p>Läs mer</p> : <p>Läs mindre</p>}
              </div>
            </div>
            {/* <a href="https://www.aftonbladet.se/malmo/" className="abep--ad">
              <p>Få senaste nyheterna från Malmö</p>
              <div className="logos">
                <img
                  src={vad_hander}
                  alt="Logo Aftonbladet"
                />
              </div>
            </a> */}
          </div>
          <WidgetComponent {...this.props} />
        </div>
      </>
    ) : null;
  }
}

import React, { useState, useEffect, lazy, Suspense } from "react";
import "../styles/events-widget.scss";
import Event from "./event";
import Eventbox from "./eventbox";
import apicall from "../utils/apicall";
import dayjs from "dayjs";
import pym from "pym.js";
import "dayjs/locale/sv";
import config from "../utils/config";
import _ from "lodash";
import Waypoint from "react-waypoint";
import AppNexusAd from './generateAd';
import vad_hander from "../images/vad-hander-logo.svg";
const Mapbox = lazy(() => import("./mapbox"));

export default props => {
  const TICKET_MASTER = config.TICKET_MASTER;
  let categoryParam = "alla";
  let cityParam = "malmo";
  const params = props.match.params;
  let widget = "malmo";
  if (_.has(params, "city")) cityParam = params.city;
  if (_.has(params, "category")) categoryParam = params.category;
  if (props.match.path.includes("/evenemang/uppsala")) {
    widget = "uppsala";
    cityParam = "uppsala";
  }
  if (
    !props.match.path.includes("/evenemang/uppsala") &&
    !props.match.path.includes("/evenemang/malmo")
  ) {
    widget = 'alla';
    cityParam = "alla";
  }

  // console.log('widget',widget)
  const [category, set_category] = useState(categoryParam);
  const [city, set_city] = useState(cityParam);
  const [selectedDate, set_date] = useState();
  const [date_text, set_date_text] = useState("");
  const [events, set_events] = useState(null);
  const [total_events, set_total_events] = useState(0);
  const [cities, set_cities] = useState([]);
  const [per_page, set_per_page] = useState(9);
  const [page, set_page] = useState(1);
  const [map_class, set_map_class] = useState();
  const [toggleText, set_toggle_text] = useState();
  const [loader, set_loader] = useState(false);
  const [sessionTime, set_session_time] = useState();
  const [categories, set_categories] = useState([]);

  if (
    dayjs(sessionTime)
      .add(24, "hour")
      .isBefore(dayjs(new Date()))
  ) {
    config.localforage
      .removeItem(`city-map-malmo`)
      .then(function() {})
      .catch(function(err) {
        console.log(err);
      });
    config.localforage
      .removeItem(`city-map-uppsala`)
      .then(function() {})
      .catch(function(err) {
        console.log(err);
      });
      config.localforage
      .removeItem(`city-map-alla`)
      .then(function() {})
      .catch(function(err) {
        console.log(err);
      });
  }

  const getSessionData = async () => {
    config.localforage.getItem("session-time", (err, sessionTime) => {
      if (_.isDate(sessionTime)) {
        set_session_time(sessionTime);
      }
    });
  };

  useEffect(() => {
    getSessionData();
  }, []);

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    getCategories();
  }, [city, date_text]);

  const getCategories = () => {
    apicall.getCategories(city, date_text, widget).then(resp => {
      set_categories(resp);
    });
  };

  const getCities = () => {
    // console.log("get cities called----->");
    config.localforage.getItem(`city-map-${widget}`).then(data => {
      if (_.isEmpty(data)) {
        setSessionTime();
        apicall.getCities(widget).then(resp => {
          set_cities(resp);
        });
      } else {
        set_cities(Object.values(data));
      }
    });
  };

  const getEvents = () => {
    apicall.getCalenderEvents(widget).then(resp => {
      set_events(resp.events);
      set_total_events(resp.total_events);
    });
  };

  useEffect(() => {
    filterEvents();
  }, [category, category, city, date_text, per_page, page]);

  const filterEvents = () => {
    apicall
      .filterCalenderEvents(category, city, date_text, per_page, page, widget)
      .then(resp => {
        set_events(resp.events);
        set_total_events(resp.total_events);
        set_loader(false);
      });
  };

  useEffect(() => {
    toggleMap();
  }, []);

  const toggleMap = () => {
    if (map_class === "hide-map") {
      set_map_class("show-map");
      set_toggle_text("Dölj karta");
    } else {
      set_map_class("hide-map");
      set_toggle_text("Visa Karta");
    }
  };

  useEffect(() => {
    defaultFilter();
  }, []);

  const defaultFilter = () => {
    if (category === undefined) {
      set_category("alla");
    }
  };

  useEffect(() => {
    callPym();
  }, [events]);

  const callPym = () => {
    if (events) {
      var pymChild = new pym.Child({ id: "abew--widget" });
      pymChild.sendHeight();
      // pym.Child({ polling: 50 });
    }
  };

  const setSessionTime = () => {
    config.localforage.setItem("session-time", new Date(), function(err) {});
  };

  const loadMoreEvents = (previousPosition, currentPosition) => {
    let p = page + 1;
    set_page(p);
    set_loader(true);
  };

  const categoryFilters = ["Alla"];
  let pushOtherCategory = false;
  if (categories)
    categories.map((category, index) => {
      if (_.has(config.categoryMap, _.lowerCase(category.name)))
        categoryFilters.push(category.name);
      else pushOtherCategory = true;
    });
  if (pushOtherCategory && !categoryFilters.includes("Övrigt")) categoryFilters.push("Övrigt");

  return (
    <>
      <div className="abew--wrapper">
        <div className="abep--pageheader">
          <a onClick={() => props.history.goBack()} className="abep--back-link">
            Tillbaka
          </a>
          <a
            href={`https://vahander.se/evenemang/${widget}/`}
            className="abep--pageheader-logo"
          >
            <img src={vad_hander} alt="Logo Aftonbladet" />
            <div className="hide logo-divider">/</div>
            {widget === "malmo" && (
              <img src="/evenemang/uppsala/static/logo-malmo-v2.svg" alt="" />
            )}
          </a>
          <div className="last-child"></div>
        </div>

        <div className={"abew--widget " + map_class}>
          {/* <div className="abew--widgetHeader"> */}
            {/* <img
              src="/evenemang/uppsala/static/logo-eventkalendern-2.svg"
              alt="Eventkalendern"
              onClick={() => set_category("alla")}
            /> */}
            {/* <div className="abew--visapakarta">
              <a href={`/evenemang/${widget}/alla/`} target="_parent">
                Visa på karta
              </a>
            </div>
            <div className="abew--visakarta">
              <a
                onClick={e => {
                  e.preventDefault();
                  toggleMap();
                }}
              >
                {toggleText}
              </a>
            </div>
          </div> */}

          <div className={"abew--karta " + map_class}>
            {
              <Suspense fallback={<div />}>
                <Mapbox
                  events={events}
                  widget={widget}
                  lat={
                    widget === "uppsala"
                      ? config.UPPSALA_COORDINATES.lat
                      : config.MALMO_COORDINATES.lat
                  }
                  lng={
                    widget === "uppsala"
                      ? config.UPPSALA_COORDINATES.lng
                      : config.MALMO_COORDINATES.lng
                  }
                />
              </Suspense>
            }
          </div>
          <div className="abew--body">
            {/* city & date filter */}
            <div className="abew--filter">
              <div className="abew--city">
                <select
                  className="abew--select"
                  value={city}
                  onChange={event => {
                    {
                      set_city(event.target.value);
                      set_page(1);
                      set_category("alla");
                      // change url based on city
                      // console.log("wiz==> ", widget, city);
                      if (props.history) {
                        if (
                          city === "" ||
                          event.target.value === "" ||
                          event.target.value === widget
                        ) {
                          widget === TICKET_MASTER
                            ? props.history.push(`/evenemang/`)
                            : props.history.push(
                                `/evenemang/${widget}/${_.lowerCase("alla")}/`
                              );
                        } else {
                          {
                            widget === TICKET_MASTER
                              ? props.history.push(
                                  `/evenemang/${_.lowerCase(category)}/${
                                    event.target.value
                                  }/`
                                )
                              : props.history.push(
                                  `/evenemang/${widget}/${_.lowerCase(
                                    category
                                  )}/${event.target.value}/`
                                );
                          }
                        }
                      }
                    }
                  }}
                >
                  <option value="">Alla orter</option>
                  {cities &&
                    cities.map((citi, index) => (
                      <option key={citi + index} value={_.lowerCase(citi)}>
                        {citi}
                      </option>
                    ))}
                </select>
              </div>

              <div className="abew--time active">
                <select
                  className="abew--select"
                  value={date_text}
                  onChange={event => {
                    {
                      set_date_text(event.target.value);
                      set_page(1);
                      set_category("alla");
                    }
                  }}
                >
                  <option value="">Alla dagar</option>
                  <option value="today">Idag</option>
                  <option value="this_week">I veckan</option>
                  <option value="this_weekend">I helgen</option>
                </select>
              </div>
            </div>
            {/* category filter */}
            <div className="abew--categories">
              <div className="abew--categories-scrollWrapper">
                {categoryFilters &&
                  categoryFilters.map((filterButton, index) => {
                    let filterButtonSlug = _.lowerCase(filterButton);
                    return (
                      <div
                        className={
                          "abew--category " +
                          filterButtonSlug +
                          (filterButtonSlug === _.lowerCase(category)
                            ? " active"
                            : "")
                        }
                        onClick={() => {
                          set_category(filterButtonSlug);
                          set_page(1);
                          // change url based on category
                          if (!_.isEmpty(props.history)) {
                            if (city === "" || _.lowerCase(city) === widget) {
                             
                              {
                                widget === TICKET_MASTER
                                  ? props.history.push(
                                      `/evenemang/${filterButtonSlug}/`
                                    )
                                  : props.history.push(
                                      `/evenemang/${widget}/${filterButtonSlug}/`
                                    );
                              }
                            } else {
                              {
                                widget === TICKET_MASTER
                                  ? props.history.push(
                                      `/evenemang/${filterButtonSlug}/${_.lowerCase(
                                        city
                                      )}/`
                                    )
                                  : props.history.push(
                                      `/evenemang/${widget}/${filterButtonSlug}/${_.lowerCase(
                                        city
                                      )}/`
                                    );
                              }
                            }
                          }
                        }}
                        key={"category" + filterButton}
                      >
                        <div className="abew--topic">
                          <div className="abew--type" />
                          <p>{filterButton}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="abew--categories-fadeStart" />
              <div className="abew--categories-fadeEnd" />
            </div>

            <Event count={total_events} date_text={date_text} />
            <div className="abew--eventboxes-container">
              {!_.isEmpty(events) ? (
                events.map((event, index) => {
                  let startDate = dayjs(event.actual_start_datetime)
                    .locale("sv")
                    .format("D MMM HH:mm");
                  let address = new Array(event.campaign_address, event.city);

                  var addTriggerToLoad = "";
                  if (total_events > 9 && events.length === index + 2) {
                    addTriggerToLoad = (
                      <>
                        {" "}
                        <Waypoint
                          key={index}
                          onEnter={({ previousPosition, currentPosition }) =>
                            loadMoreEvents(previousPosition, currentPosition)
                          }
                        />
                      </>
                    );
                    return addTriggerToLoad;
                  }

                  return (
                    <Eventbox
                      key={"event" + index}
                      date={startDate}
                      name={event.name}
                      location={event.campaign_address}
                      link={event.campaignUrl}
                      id={event.id}
                      category={event.category}
                      city={event.city}
                      widget={widget}
                    />
                  );
                })
              ) : (
                <div style={{ padding: "10px" }}>
                  <div className="no-events-text">Inga aktuella evenemang</div>
                  <div
                    className="no-events-reset-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      set_category("alla");
                      set_city("");
                      set_date_text("");
                      {
                        widget === TICKET_MASTER
                          ? props.history.push(`/evenemang/`)
                          : props.history.push(`/evenemang/${widget}/alla/`);
                      }
                    }}
                  >
                    Visa alla
                  </div>
                </div>
              )}
            </div>
            <div
              className={loader ? "loader-container" : "loader-container hide"}
            >
              <div className="loader"></div>
            </div>
          </div>
        </div>
        {(window.innerWidth > 500 && props.match.path.includes(':eventTitle') && widget === "uppsala") && (
              <>  
              <div className="ad-iframe" style={{textAlign: 'center'}}>
                  <iframe
                    src="https://secure.adnxs.com/tt?id=18026689&size=320x320&kw_se-aftonbladet-local=uppsala"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    topMargin="0"
                    leftMargin="0"
                    allowTransparency="true"
                    width="300"
                    height="480"
                  ></iframe>
                </div> 
                  {/* <AppNexusAd/>                   */}
                </>
              )}
      </div>
    </>
  );
};
